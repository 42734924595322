<template>
  <el-drawer
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    size="50%"
  >
    <hot-table
      ref="hotTable"
      style="overflow: hidden; width: 100%; height: 100%;"
      :data="tableData"
      license-key="non-commercial-and-evaluation"
      :nested-headers="nestedHeaders"
      :col-headers="true"
      :row-headers="true"
      width="100%"
    >
      <hot-column data="cartonNo" />
      <hot-column data="fabNo" />
      <hot-column data="trackingNumber" />
      <hot-column data="chargeWeight" />
      <hot-column data="confirmVolume" />
      <hot-column data="remark" />
    </hot-table>
  </el-drawer>
</template>

<script>
import { HotTable, HotColumn } from '@handsontable/vue';
export default {
  components: {
    HotTable,
    HotColumn
  },
  props: ['dialogVisible', 'goodsInfo', 'orderId'],
  data() {
    return {
      // 嵌套表头
      nestedHeaders: [['箱号', 'FBA箱号', '转单号', '收费重', '最终确认体积', '备注']],
      tableData: [],
      hotInstance: null
    };
  },
  created() {
    this.tableData = JSON.parse(JSON.stringify(this.goodsInfo));
  },
  mounted() {
    this.$nextTick(() => {
      this.hotInstance = this.$refs.hotTable.hotInstance;
      this.hotInstance.updateData(this.tableData);
    });
  },
  methods: {
    closeDialog() {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>

<style scoped>
::v-deep .el-form-item__content {
  font-size: 16px;
}
::v-deep .el-form-item__label {
  font-size: 16px;
}
::v-deep .el-drawer__header {
  font-size: 18px;
}
::v-deep .el-drawer__body {
  overflow-y: auto;
}
.celiangData {
  display: flex;
}
.celiangData span {
  white-space: nowrap;
  display: inline-block;
  margin: 0 10px;
}
</style>
