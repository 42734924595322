<template>
  <div class="orderdetails teblestyle">
    <div class="c-box" style="padding-top: 10px;">
      <div class="danhao">
        <div>订单号：{{ forData.orderNo }}</div>
        <div>订单状态：{{ forData.frontStatusName }}</div>
        <div>创建时间：{{ forData.createTime }}</div>
      </div>
      <el-card style="margin: 20px 0">
        <div class="infodiv">
          <div class="xinxi">
            <div class="icontit">基础信息</div>
            <div class="xinxifo">
              <div>商品名称：{{ forData.names }}</div>
              <div>货物类型：<span v-if="forData.gidtype === 1">普货</span><span v-if="forData.gidtype == 2">特货</span></div>
            </div>
            <div class="xinxifo">
              <div>报关方式：<span v-if="forData.needDeclare == 0">买关</span><span v-if="forData.needDeclare == 1">独立</span></div>
              <div>结算方式：<span>{{ computeClearingWay }}</span></div>
            </div>
            <div class="xinxifo">
              <div>订柜尺寸：{{ forData.reserveSize }}</div>
              <div>起 运 港：{{ forData.startShipmentName }}</div>
            </div>
            <div class="xinxifo">
              <div>截仓日期：{{ forData.jcTime }}</div>
              <div>截亏仓日期：{{ forData.jkcTime }}</div>
            </div>
            <div class="xinxifo">
              <div>航 程：{{ forData.voyageDay }}</div>
              <div>预计到达：{{ forData.estimatedTime }}</div>
            </div>
          </div>
          <div class="guiji">
            <div class="icontit">物流轨迹</div>
            <div class="buzhoutiao">
              <el-steps direction="vertical" :active="1" space="80px">
                <el-step v-for="(item, index) in forData.logisticsTrackVOS" :key="item.id" :title="item.createTime" :description="item.description">
                  <template slot="icon">
                    <div class="el-step__icon-inner">{{ forData.logisticsTrackVOS.length - index }}</div>
                  </template>
                </el-step>
              </el-steps>
            </div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">报价部分 <span style="margin-left: 20px; font-weight: normal; color: #507EFF; cursor: pointer;" @click="showViewQuoteDialog">查看报价</span></div>
          <div v-for="item in forData.oceanFeeList" :key="item.id" class="filediv">
            <div>规格名称：{{ item.specificationName }}</div>
            <div>数量单位：
              <span v-show="item.unit === 1">公斤</span>
              <span v-show="item.unit === 2">方</span>
              <span v-show="item.unit === 3">票</span>
              <span v-show="item.unit === 4">柜</span>
              <span v-show="item.unit === 5">方</span>
            </div>
            <div>单价：{{ item.unitPrice }}</div>
            <div>币种代码：{{ item.currencyCode }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">计费重信息</div>
          <div class="danhao">
            <div>
              <p v-show="forData.orderStatisticalCount.chargeWeight !== 0" class="unitNumber">{{ forData.chargeWeight }} KG</p>
              <p v-show="forData.orderStatisticalCount.chargeWeight === 0" class="unitNumber">无</p>
              <p class="unit">收费重</p>
            </div>
            <!-- <div v-show="forData.discountsChargeWeight">
              <p v-show="forData.discountsChargeWeight !== 0" class="unitNumber">{{ forData.discountsChargeWeight }} KG</p>
              <p v-show="forData.discountsChargeWeight === 0" class="unitNumber">无</p>
              <p class="unit">固定计费重</p>
            </div> -->
            <div>
              <p v-show="forData.orderStatisticalCount.volumeWeight !== 0" class="unitNumber">{{ forData.orderStatisticalCount.volumeWeight }} KG</p>
              <p v-show="forData.orderStatisticalCount.volumeWeight === 0" class="unitNumber">无</p>
              <p class="unit">材积重</p>
            </div>
            <div>
              <p v-show="forData.orderStatisticalCount.bubbleCoefficient !== 0" class="unitNumber">{{ forData.orderStatisticalCount.bubbleCoefficient }}</p>
              <p v-show="forData.orderStatisticalCount.bubbleCoefficient === 0" class="unitNumber">无</p>
              <p class="unit">计泡系数</p>
            </div>
            <div>
              <p v-show="forData.orderStatisticalCount.actualWeight !== 0" class="unitNumber">{{ forData.orderStatisticalCount.actualWeight }} KG</p>
              <p v-show="forData.orderStatisticalCount.actualWeight === 0" class="unitNumber">无</p>
              <p class="unit">实际重量</p>
            </div>
            <div>
              <p v-show="forData.orderStatisticalCount.actualVolume !== 0" class="unitNumber">{{ forData.orderStatisticalCount.actualVolume }} m³</p>
              <p v-show="forData.orderStatisticalCount.actualVolume === 0" class="unitNumber">无</p>
              <p class="unit">实际体积</p>
            </div>
            <div>
              <p v-show="forData.orderStatisticalCount.totalCartons !== 0" class="unitNumber">{{ forData.orderStatisticalCount.totalCartons }} 箱</p>
              <p v-show="forData.orderStatisticalCount.totalCartons === 0" class="unitNumber">无</p>
              <p class="unit">总箱数</p>
            </div>
            <div>
              <el-button v-show="forData.frontStatusCode == 20 && statusFlag === '2'" type="primary" round size="mini" style="width: 90px" @click="querenjizhong">确认计费重</el-button>
              <span v-show="forData.frontStatusCode == 20 && statusFlag === '1'" style="font-size:17px; color:red">计费重已确认</span>
            </div>
          </div>
          <div class="tablediv">
            <el-collapse>
              <el-collapse-item>
                <el-table :data="forData.orderCaseVOList" stripe style="width: 100%" border>
                  <el-table-column prop="cartonNo" label="箱号" align="center" />
                  <el-table-column prop="fabNo" label="FBA箱号" align="center" />
                  <el-table-column label="客户自测" align="center">
                    <el-table-column
                      prop="asnLength"
                      label="长(cm)"
                      align="center"
                    />
                    <el-table-column
                      prop="asnWidth"
                      label="宽(cm)"
                      align="center"
                    />
                    <el-table-column
                      prop="asnHeight"
                      label="高(cm)"
                      align="center"
                    />
                    <el-table-column
                      prop="asnWeight"
                      label="重量(kg)"
                      align="center"
                    />
                    <el-table-column
                      prop="asnVolume"
                      label="体积(m³)"
                      align="center"
                    />
                  </el-table-column>
                  <!-- <el-table-column label="仓库测量" align="center">
                    <el-table-column
                      prop="wmsLength"
                      label="长(cm)"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="wmsWidth"
                      label="宽(cm)"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="wmsHeight"
                      label="高(cm)"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="wmsWeight"
                      label="重量(kg)"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="wmsVolume"
                      label="体积(m³)"
                      align="center"
                    ></el-table-column>
                  </el-table-column> -->
                  <el-table-column label="计费重" align="center">
                    <el-table-column
                      prop="confirmLength"
                      label="长(cm)"
                      align="center"
                    />
                    <el-table-column
                      prop="confirmWidth"
                      label="宽(cm)"
                      align="center"
                    />
                    <el-table-column
                      prop="confirmHeight"
                      label="高(cm)"
                      align="center"
                    />
                    <el-table-column
                      prop="confirmWeight"
                      label="重量(kg)"
                      align="center"
                    />
                    <el-table-column
                      prop="confirmVolume"
                      label="体积(m³)"
                      align="center"
                    />
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>

          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">商品信息</div><el-button type="primary" icon="el-icon-document-copy" size="mini" style="margin-bottom: 3px" @click="excelCopyDialog = true">复制</el-button>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane label="商品信息" name="first">
              <div class="tablediv">
                <el-table :data="forData.orderShopVOList" stripe style="width: 100%">
                  <el-table-column label="SKU" align="center">
                    <template slot-scope="scope">
                      <span class="skutit" @click="skutitdetails(scope.row.goodId)">{{ scope.row.sku }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="nameCn"
                    label="商品名称（中文）"
                    align="center"
                  />
                  <el-table-column prop="quantity" label="发货数量" align="center" />
                  <el-table-column prop="hsCode" label="海关编码" align="center" />
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="装箱单" name="second">
              <el-table
                class="freightRate-table center"
                :data="goodsOrderCase"
                style="width: 100%"
                height="500px"
              >
                <el-table-column type="expand">
                  <template slot-scope="scope">
                    <el-table
                      class="freightRate-table center"
                      :data="scope.row.orderCaseShopList"
                      style="width: 100%"
                    >
                      <el-table-column prop="cartonNo" label="箱号" align="center" />
                      <el-table-column prop="nameCn" label="中文名" align="center" />
                      <el-table-column prop="hsCode" label="海关编码" align="center" />
                      <el-table-column prop="quantity" label="数量" align="center" />
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="cartonNo" label="箱号" />
                <el-table-column prop="fabNo" label="FBA箱号" />
                <el-table-column prop="chargeWeight" label="收费重" />
                <el-table-column prop="confirmVolume" label="最终确认体积" />
                <el-table-column prop="remark" label="备注" />
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">报关文件</div>
          <div
            v-for="file in forData.orderCustomsFileVOList"
            :key="file.id"
            class="upload"
          >
            {{ file.fileName }}：
            <span v-for="objname in file.templateUrlVOS" :key="objname.id" class="filespan" @click="downfile(objname)">{{ objname.fileName }}</span>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">清关文件</div>
          <div
            v-for="file in forData.orderClearanceFileVOList"
            :key="file.id"
            class="upload"
          >
            {{ file.fileName }}：
            <span v-for="objname in file.templateUrlVOS" :key="objname.id" class="filespan" @click="downfile(objname)">{{ objname.fileName }}</span>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">订单备注</div>
          <div class="filediv">
            <div>{{ forData.remark }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">提货地址</div>
          <div class="tablediv" style="position: relative;">
            <el-table :data="forData.orderPickVOList || []" stripe style="width: 100%">
              <el-table-column prop="warehouseNo" label="进仓单号" />
              <el-table-column prop="pickTime" label="提货时间" width="165px" />
              <el-table-column prop="weight" label="重量(kg)" />
              <el-table-column prop="volume" label="体积(m³)" />
              <el-table-column prop="totalCarton" label="箱数" />
              <el-table-column prop="contacts" label="联系人" />
              <el-table-column prop="phone" label="联系电话" />
              <el-table-column prop="countryName" label="国家" />
              <el-table-column prop="stateName" label="省/州" />
              <el-table-column prop="cityName" label="城市" />
              <el-table-column prop="address" label="提货地址" />
              <el-table-column label="下载" width="150px">
                <template slot-scope="scope">
                  <div>
                    <el-button type="primary" size="mini" style="margin-bottom: 10px;width:130px" @click="downloadPdfWarehouseNoByPickId1(scope.row)">下载进仓单（PDF）</el-button>
                  </div>
                  <div>
                    <el-button type="primary" size="mini" style="margin-bottom: 10px;width:130px" @click="downloadPdfWarehouseNoByPickId2(scope.row)">下载箱唛（PDF）</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div v-if="!forData.orderPickVOList.length" class="btn-group-downfile">
              <span style="margin-right:10px">客人自己送仓</span>
              <el-button type="primary" size="mini" style="margin-bottom: 10px" @click="downloadPdfWarehouseNoByPickId1">下载进仓单（PDF）</el-button>
              <el-button type="primary" size="mini" @click="downloadPdfWarehouseNoByPickId2">下载箱唛（PDF）</el-button>
            </div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">集货仓库</div>
          <div class="filediv">
            <div>集货仓库：{{ forData.shippingAreaVO.warehouseName }}</div>
            <div>联系人：{{ forData.shippingAreaVO.contacts }}</div>
            <div>联系电话：{{ forData.shippingAreaVO.contactNumber }}</div>
            <div>地址：{{ forData.shippingAreaVO.addressFirst }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">目的地仓库</div>
          <div class="filediv">
            <div>目的地仓库：<span style="color: #507EFF;cursor: pointer;" @click="DestinationWarehouse = true">{{ forData.fabWarehouseVO.warehouseName }}</span></div>
            <div>联系人：{{ forData.fabWarehouseVO.contacts }}</div>
            <div>联系电话：{{ forData.fabWarehouseVO.contactNumber }}</div>
            <div>地址：{{ forData.fabWarehouseVO.addressFirst }}</div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">费用明细</div>
          <div class="tablediv">
            <el-table
              :data="forData.orderCostDetailVO.orderCopeReceivableVOS"
              stripe
              style="width: 100%"
            >
              <el-table-column prop="costName" label="费用名称" align="center" />
              <el-table-column prop="count" label="收费重" align="center">
                <template slot-scope="scope">
                  <span v-show="!scope.row.labourAffirm">待人工确认</span>
                  <span v-show="scope.row.labourAffirm">{{ scope.row.count }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="unitPrice" label="单价" align="center">
                <template slot-scope="scope">
                  <!-- <span v-show="!scope.row.labourAffirm">待人工确认</span> -->
                  <span v-show="scope.row.labourAffirm">{{ scope.row.unitPrice }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="金额" align="center">
                <template slot-scope="scope">
                  <!-- <span v-show="!scope.row.labourAffirm">待人工确认</span> -->
                  <span v-show="scope.row.labourAffirm">{{ scope.row.amount }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="currencyName" label="币钟" align="center">
                <template slot-scope="scope">
                  <!-- <span v-show="!scope.row.labourAffirm">待人工确认</span> -->
                  <span v-show="scope.row.labourAffirm">{{ scope.row.currencyName }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="calculateWay" label="计算方式" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.calculateWay === 1">自动</span>
                  <span v-show="scope.row.calculateWay === 2">手动</span>
                </template>
              </el-table-column>
              <el-table-column prop="source" label="数量来源" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.source === 1">计费重</span>
                  <span v-show="scope.row.source === 2">固定</span>
                  <span v-show="scope.row.source === 3">箱数</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="是否生成账单" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.status === 0">未生成账单</span>
                  <span v-show="scope.row.status === 1">已生成账单</span>
                </template>
              </el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" />
            </el-table>
            <div class="heji">
              <span class="tit">合计：</span><span v-for="item in forData.orderCostDetailVO.orderCopeReceivableAggregate" :key="item.cid" style="display:inline-block; margin-right: 20px">{{ item.amount + item.currencyName }}</span>
            </div>
          </div>
        </div>
      </el-card>
      <el-card style="margin-bottom: 20px">
        <div class="jifeizhong">
          <div class="icontit">账单确认</div>
          <div class="tablediv">
            <el-table :data="tableData" stripe style="width: 100%">
              <el-table-column prop="billCode" label="账单编号" align="center" />
              <el-table-column prop="createTime" label="账单日期" align="center" />
              <el-table-column prop="customerName" label="客户名称" align="center" />
              <el-table-column prop="amountFormat" label="账单金额" align="center" />
              <el-table-column prop="currencyName" label="币钟" align="center" />
              <el-table-column prop="status" label="客户确认" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.confirmBill === 0">未确认</span>
                  <span v-show="scope.row.confirmBill === 1">已确认</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="账单状态" align="center">
                <template slot-scope="scope">
                  <span v-show="scope.row.status === 0">未付款</span>
                  <span v-show="scope.row.status === 1">已付款</span>
                </template>
              </el-table-column>
              <el-table-column prop="remarks" label="备注" align="center" />
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="mini"
                    class="m_bottom10"
                    @click="receiveBillDetail(scope.row.id)"
                  >查看明细</el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    class="m_bottom10"
                    style="margin-left: 0"
                    :disabled="scope.row.confirmBill === 1"
                    @click="confirmBill(scope.row.id)"
                  >确认账单</el-button>
                  <el-button
                    type="primary"
                    size="mini"
                    @click="downloadReceiveBillExcel(scope.row)"
                  >下载</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="heji" v-if="tableData.length">
              <span class="tit">合计：</span><span>2380美元</span><br /><span
                >1000人民币</span
              >
            </div> -->
          </div>
          <div v-show="forData.status == 50" class="queren">
            <el-button
              type="primary"
              round
              size="mini"
              style="width: 90px"
              @click="zhangdanqueren"
            >确认</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
      title="报价详细信息"
      :visible.sync="viewQuoteDialog"
      width="1200px"
      @close="viewQuoteDialog = false"
    >
      <div class="templateQuote">
        <el-row>
          <el-col :span="6">
            <p>产品名称：{{ offerDetails.names }}</p>
          </el-col>
          <el-col :span="6">
            <p>运输方式：{{ offerDetails.tid }}</p>
          </el-col>
          <el-col :span="6">
            <p>货物类型：<span v-show="offerDetails.gidtype==1" style="font-weight:bold;">普货</span><span v-show="offerDetails.gidtype==2" style="font-weight:bold;">特货</span></p>
          </el-col>
          <el-col :span="6">
            <p>起 运 港：{{ offerDetails.startShipment }}</p>
          </el-col>
          <el-col :span="6">
            <p>目 的 港：{{ offerDetails.destinationPort }}</p>
          </el-col>
          <el-col :span="6">
            <p>航 程：<span v-show="offerDetails.voyageDay" style="font-weight:bold;">{{ offerDetails.voyageDay }}天</span></p>
          </el-col>
          <el-col :span="6">
            <p>预计到达：{{ offerDetails.estimatedTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>开船日期：{{ offerDetails.sailTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>截单日期：{{ offerDetails.cutOffTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>截仓日期：{{ offerDetails.jcTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>截亏仓日期：{{ offerDetails.jkcTime }}</p>
          </el-col>
          <el-col :span="6">
            <p>订柜尺寸：<span v-for="(item,i) in forData.oceanFeeList" :key="i" :title="item?item.specificationName:''"> {{ item?item.specificationName:'' }}</span></p>
          </el-col>
          <el-col :span="6">
            <p>金额：<span v-for="(item,i) in forData.oceanFeeList" :key="i" :title="item?item.specificationName:''"> {{ item?item.unitPriceFormat:'' }}</span></p>
          </el-col>
          <el-col :span="6">
            <p>集货仓库：{{ forData.storeGoodsWarehouseName }}</p>
          </el-col>
          <el-col :span="6">
            <p>目的地仓库：{{ forData.destinationWarehouseName }}</p>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      v-if="goods_Show"
      title="商品详情"
      :visible.sync="goods_Show"
      width="1000px"
      @close="goods_Show = false"
    >
      <commoditydetails :id="goods_id" :sc="true" @close="goods_Show = false" />
      <div style="text-align:center;">
        <el-button type="primary" size="mini" style="margin-bottom: 10px" @click="goods_Show = false">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      :title="forData.fabWarehouseVO.warehouseName"
      :visible.sync="DestinationWarehouse"
      width="1000px"
      @close="DestinationWarehouse = false"
    >
      <el-row style="margin-bottom: 20px">
        <el-col :span="6"><label>仓库代码：</label><span>{{ fabWarehouseDetail.warehouseCode }}</span></el-col>
        <el-col :span="6"><label>仓库名称：</label><span>{{ fabWarehouseDetail.warehouseName }}</span></el-col>
        <el-col :span="6"><label>公司名称：</label><span>{{ fabWarehouseDetail.companyName }}</span></el-col>
        <el-col :span="6"><label>联系人：</label><span>{{ fabWarehouseDetail.contacts }}</span></el-col>
      </el-row>
      <el-row style="margin-bottom: 20px">
        <el-col :span="6"><label>联系电话：</label><span>{{ fabWarehouseDetail.contactNumber }}</span></el-col>
        <el-col :span="6"><label>联系手机：</label><span>{{ fabWarehouseDetail.contactPhone }}</span></el-col>
        <el-col :span="6"><label>地址：</label><span>{{ fabWarehouseDetail.addressFirst }}</span></el-col>
        <el-col :span="6"><label>省/州名：</label><span>{{ fabWarehouseDetail.stateName }}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="6"><label>城市名：</label><span>{{ fabWarehouseDetail.cityName }}</span></el-col>
        <el-col :span="6"><label>用户：</label><span>{{ fabWarehouseDetail.auditUserName }}</span></el-col>
        <el-col :span="6"><label>区域：</label><span>{{ fabWarehouseDetail.areaGroup }}</span></el-col>
      </el-row>
    </el-dialog>
    <el-dialog title="查看明细" :visible.sync="receiveBillDetailDialog" width="60%">
      <el-row :gutter="20">
        <el-col :span="12">
          <span>帐单编号：</span>
          <span>{{ receiveBillDetailData.billCode }}</span>
        </el-col>
        <el-col :span="12">
          <span>账单日期：</span>
          <span>{{ receiveBillDetailData.createTime }}</span>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <span>订单编号：</span>
          <span>{{ receiveBillDetailData.orderNo }}</span>
        </el-col>
        <el-col :span="12">
          <span>客户名称：</span>
          <span>{{ receiveBillDetailData.customerName }}</span>
        </el-col>
      </el-row>
      <div class="offerList">
        <el-table
          :data="receiveBillDetailData.receivableBillDetailVOS"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="costName"
            label="费用名称"
            align="center"
          />
          <el-table-column
            prop="amount"
            label="金额"
            align="center"
          />
          <el-table-column
            prop="currencyName"
            label="币钟"
            align="center"
          />
          <el-table-column label="备注" prop="remarks" align="center" />
        </el-table>
        <div class="heji">
          合计：
          <span
            v-for="(item, index) in receiveBillDetailData.billAmountList"
            :key="index"
            style="margin-right: 20px"
          >{{ item }}</span>
        </div>
      </div>
    </el-dialog>
    <transfer-no-drawer
      v-if="excelCopyDialog"
      :dialog-visible.sync="excelCopyDialog"
      :goods-info="goodsOrderCase"
    />
  </div>
</template>

<script>
import {
  orderdateils,
  affirmCounterWeightInfo,
  billConfirmedRec,
  downloadWarehouseNoByPickId1,
  downloadWarehouseNoByPickId2,
  downloadWarehouseNoByOrderId1,
  downloadWarehouseNoByOrderId2,
  downloadPdfWarehouseNoByPickId1,
  downloadPdfWarehouseNoByPickId2,
  downloadPdfWarehouseNoByOrderId1,
  downloadPdfWarehouseNoByOrderId2,
  findfabWarehouseByWarehouseCode,
  findOrderIsConfirmBilling,
  findReceivableBillMasterByOrderId,
  lookDetail,
  downloadBills,
  confirmBill,
  downLoadFiles
} from '@/api/order.js';
import { InfoFare, findClearingWay, findOrderCaseByOrderId } from '@/api/freight.js';
import commoditydetails from './commoditydetails';
import TransferNoDrawer from './components/TransferNoDrawer';
import { saveAs } from 'file-saver';
export default {
  name: 'Orderdetails',
  components: {
    commoditydetails,
    TransferNoDrawer
  },
  props: {},
  data: function() {
    return {
      forData: {
        shippingAreaVO: {},
        fabWarehouseVO: {},
        orderCostDetailVO: {},
        orderPickVOList: [],
        orderStatisticalCount: {}
      },
      tableData: [],
      guijin: '',
      viewQuoteDialog: false,
      DestinationWarehouse: false,
      goods_Show: false,
      goods_id: '',
      fabWarehouseDetail: {},
      offerDetails: [],
      statusFlag: '',
      ClearingWay: [],
      activeName: 'first',
      receiveBillDetailDialog: false,
      goodsOrderCase: [], // 装箱单数据
      receiveBillDetailData: {},
      excelCopyDialog: false
    };
  },
  computed: {
    computeClearingWay() {
      let clearingway;
      this.ClearingWay.forEach(item => {
        if (item.id === this.forData.clearingWay) {
          clearingway = item.name;
        }
      });
      return clearingway;
    }
  },
  watch: {},
  created() {
    this.getorderinfo();
    this.findOrderIsConfirmBilling();
    this.findClearingWay();
    this.findOrderCaseByOrderId();
    this.findReceivableBillMasterByOrderId();
  },
  mounted() {},
  methods: {
    findOrderIsConfirmBilling() {
      findOrderIsConfirmBilling({ id: this.$route.query.id })
        .then(res => {
          console.log(res);
          if (+res.data.code === 0) {
            this.statusFlag = res.data.data.statusFlag;
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        });
    },
    getorderinfo() {
      orderdateils({ id: this.$route.query.id }).then(result => {
        if (result.data.code == 0) {
          console.log(result.data);
          const res = result.data.data;
          res.fabWarehouseVO = res.fabWarehouseVO || {};
          this.forData = res;
          this.findfabWarehouseByWarehouseCode();
        }
      });
    },
    skutitdetails(id) {
      this.goods_Show = true;
      this.goods_id = id;
    },
    querenjizhong() {
      this.$confirm(
        '订单将以计费重计算订单费用，请确认',
        '计费重确认',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }
      ).then(() => {
        affirmCounterWeightInfo({
          orderId: +this.$route.query.id,
          statusFlag: '1'
        }).then((result) => {
          if (result.data.code == 0) {
            this.$message({
              message: '成功',
              duration: 1500,
              type: 'success'
            });
          }
        });
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Deletion cancelled'
          });
        });
    },
    zhangdanqueren() {
      billConfirmedRec({
        id: this.$route.query.id
      }).then((result) => {
        if (result.data.code == 0) {
          this.$message({
            message: '成功',
            duration: 1500,
            type: 'success'
          });
        }
      });
    },
    showViewQuoteDialog() {
      InfoFare({ id: this.forData.offerInfoId })
        .then(res => {
          if (+res.data.code === 0) {
            this.offerDetails = res.data.data;
            this.viewQuoteDialog = true;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
    },
    downloadWarehouseNoByPickId1(row) {
      if (row.id) {
        window.location.href = downloadWarehouseNoByPickId1(row.id);
      } else {
        window.location.href = downloadWarehouseNoByOrderId1(this.forData.id);
      }
    },
    downloadWarehouseNoByPickId2(row) {
      if (row.id) {
        window.location.href = downloadWarehouseNoByPickId2(row.id);
      } else {
        window.location.href = downloadWarehouseNoByOrderId2(this.forData.id);
      }
    },
    downloadPdfWarehouseNoByPickId1(row) {
      if (row.id) {
        window.location.href = downloadPdfWarehouseNoByPickId1(row.id);
      } else {
        window.location.href = downloadPdfWarehouseNoByOrderId1(this.forData.id);
      }
    },
    downloadPdfWarehouseNoByPickId2(row) {
      if (row.id) {
        window.location.href = downloadPdfWarehouseNoByPickId2(row.id);
      } else {
        window.location.href = downloadPdfWarehouseNoByOrderId2(this.forData.id);
      }
    },
    findfabWarehouseByWarehouseCode() {
      findfabWarehouseByWarehouseCode({ warehouseCode: this.forData.fabWarehouseVO.warehouseCode })
        .then(res => {
          if (+res.data.code === 0) {
            this.fabWarehouseDetail = res.data.data || {};
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
    },
    findClearingWay() {
      findClearingWay()
        .then(res => {
          if (+res.data.code === 0) {
            this.ClearingWay = res.data.data;
          }
        });
    },

    // 商品信息的装箱单请求接口
    findOrderCaseByOrderId() {
      findOrderCaseByOrderId({ id: this.$route.query.id })
        .then(res => {
          if (+res.data.code === 0) {
            this.goodsOrderCase = res.data.data;
          } else {
            this.message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.log(err);
        });
    },
    findReceivableBillMasterByOrderId() {
      findReceivableBillMasterByOrderId({ id: +this.$route.query.id })
        .then(res => {
          if (+res.data.code === 0) {
            this.tableData = res.data.data;
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        }).catch(err => {
          console.log(err);
        });
    },
    receiveBillDetail(id) {
      this.receiveBillDetailDialog = true;
      lookDetail({ id })
        .then(result => {
          if (result.data.code === 0) {
            this.receiveBillDetailData = result.data.data;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    downloadReceiveBillExcel(row) {
      window.location.href = downloadBills(row.id);
    },
    confirmBill(id) {
      this.$confirm('是否确认该账单', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        confirmBill({ id, confirmBill: 1 })
          .then(res => {
            if (+res.data.code === 0) {
              this.findReceivableBillMasterByOrderId();
              this.$message({
                type: 'success',
                message: res.data.msg
              });
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg
              });
            }
          }).catch(err => {
            console.log(err);
          });
      });
    },
    // 下载附件
    downfile(item) {
      downLoadFiles({ fileName: item.fileName, filePath: item.absolutePath }).then(res => {
        saveAs(res.data, item.fileName);
      });
    }
    // arraySpanMethod(obj) {
    //   console.log(obj)
    //   if(!obj.row.labourAffirm) {
    //     if(obj.rowIndex === 3) {
    //       return [23, 1]
    //     }
    //   }
    // }
  }
};
</script>

<style scoped>
.danhao {
  width: 100%;
  height: 60px;
  background: #f3f4f9;
  border-radius: 4px;
  display: flex;
}
.danhao div {
  width: 50%;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
}
.danhao .unitNumber {
  margin: 10px 0 0 0;
  font-size: 20px;
  color: rgb(121, 121, 121);
}
.danhao .unit {
  color: rgb(121, 121, 121);
  font-weight: normal;
}
.danhao p {
  margin: 0;
  line-height: 20px;
}
.jifeizhong {
  padding-bottom: 10px;
  border-bottom: 5px solid #eeeeee;
}
.jifeizhong .danhao div {
  font-size: 14px;
}
.infodiv {
  width: 100%;
  height: auto;
  display: flex;
  border-bottom: 5px solid #eeeeee;
}
.xinxi {
  width: 50%;
}
.guiji {
  width: 50%;
  height: 300px;
}
.icontit {
  line-height: 40px;
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #2c9f9f;
}
.xinxifo {
  width: 100%;
  /* height: 40px; */
  display: flex;
}
.xinxifo div {
  width: 50%;
  text-align: left;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}
.tablediv {
  width: 100%;
  border: 1px solid #eeeeee;
  margin-top: 10px;
}
.filediv {
  display: flex;
}
.filediv div {
  width: 50%;
  text-align: left;
  line-height: 40px;
  text-indent: 10px;
}
.heji {
  padding-right: 20px;
    text-align: right;
    height: 45px;
    padding-top: 10px
}
.heji span {
  color: red;
}
.heji .tit {
  color: #333333;
  font-weight: bold;
}
.queren {
  padding-right: 10px;
  text-align: right;
  line-height: 50px;
}
.filelist{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      /* justify-content: space-between; */
}
.filelist div{
      width: 33%;

}
.skutit{
    cursor: pointer;
font-size: 14px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #507EFF;
}
.upload {
  margin-top: 20px;
  display: flex;
}
.filespan{
  margin-right: 20px;
  color: blue;
  cursor: pointer;
}
.btn-group-downfile{
  margin:10px;
}
.buzhoutiao {
  height: calc(100% - 40px);
  overflow-x: hidden;
  overflow-y: auto;
}
::v-deep .el-card {
  background-color: #f4f4f4;
  margin: 50px 0;
}
</style>
